
import { DotLoading } from "antd-mobile";
import { useEffect, useState } from "react";
import Loading from "./modules/h5/public/Loading";

const asyncMod = async () => {

  return import(`../src/modules/${process.env.REACT_APP_MOD}/index.tsx`);

}

export default () => {


  const [mod, setMod] = useState<any>(null);

  useEffect(() => {

    // alert("load...");

    asyncMod().then(ret => {

      setMod(ret.default)
    });

  }, []);

  if (mod == null) {

    return (
      <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>

         <Loading />

      </div>
    )
  }

  return (
    <>
      {mod}
    </>
  )

};
