import { DotLoading, SpinLoading } from "antd-mobile"



const Loading  = () => {


 
        return (
            <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <DotLoading color="#FF9119" style={{marginTop:"20%"}} />
            </div>
        )
    
}


export default Loading;
